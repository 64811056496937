<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />
      <b-card class="user_detail_top_section current_detail_top_section fontchanges">
        <b-row v-for="(item, index) in rowsArrayDataForCancel" :key="index">
          <b-col xl="3" lg="3" md="3">
            <h5>Cancellation Date</h5>
            <p>{{ TemplateCreatedDate(item.sessionCansallationTime).split(",")[0] }}</p>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5>Status</h5>
            <b-badge :variant="getTutorTypeTextVariant(item.status)">
                {{getAllStatusList.cancelReasonStatus[item.status]}}
              </b-badge>
          </b-col>
          <b-col xl="6" lg="3" md="3">
            <h5>Cancellation Reason</h5>
                <div
                      class="wrap-break-word"
                      v-show="true"
                      >
                      <span v-html="limitTo(item.cansalReason,220)"></span>
                      <a
                          class="readmore cancelReadMore"
                          v-b-modal="'readmore' + item.Id"
                          v-show="item.cansalReason.length >= 220"
                          
                          >Read more</a
                      >
                      <UserModal title="Cancel Reason" :text="item.cansalReason" :id="item.Id" />
                  </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card class="user_detail_top_section current_detail_top_section">
        <h4>{{bookingTitle}}</h4>
        <b-row v-for="(item, index) in rowsArrayDataForCancel" :key="index">
          <b-col xl="3" lg="3" md="3">
            <h5>Booking ID</h5>
            <p>{{ item.bookingId }}</p>
            <h5>Category</h5>
            <span class="text-nowrap underline">
               <router-link :to="{name: 'category-details', params: {id: item.categoryId}}">
                {{ item.category }}
                </router-link>
            </span>
            <h5>Tutor Name</h5>
            <span class="text-nowrap">
              <b-avatar :src="item.tutorAvtar" class="user_img" />
              <router-link
              :to="`/user-management/users/${item.bookingTutor}`"
                class="text-decoration-underline"
                >{{ item.tutorNameDetail }}</router-link
              >
            </span>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5>Session Booking Date & Time</h5>
            <p>
                {{sessionTimeCalculate(item.sessionBooking, item.sessionEnd)}}
            </p>
            <h5>Teaching Language</h5>
            <p>{{ item.teachinglanguage }}</p>
            <h5>Tutor Type</h5>
            <span class="tutor_type">
              <b-badge :style="getTutorTypeStyle(item.tutorType)">
                <!-- :variant="getTutorTypeBadgeVariant(item.tutorType)" -->
                {{ item.tutorType }}
              </b-badge>
            </span>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5>Booked Date & Time</h5>
            <p>{{ TemplateCreatedDate(item.booked) }}</p>
              <div style="visibility: hidden;padding-bottom: 20px;">
            <h5>Teaching Language</h5>
            <p>{{ item.teachinglanguage }}</p>
            </div>
            <h5>Learner Name</h5>
            <span class="text-nowrap">
              <b-avatar :src="item.learnerAvtar" class="user_img" />
              <router-link
              :to="`/user-management/users/${item.bookinglernerId}`"
                class="text-decoration-underline"
                >{{ item.learnerChildName }}</router-link
              >
            </span>
            <h5 style="margin-top:10px;">Search Tag(s)</h5>
            <div class="tag_btn" v-if="rowsArrayDataForCancel.length !== 0">
              <div v-for="(item, index) in rowsArrayDataForCancel" :key="index">
                <b-badge
                  v-for="(items, index1) in item.tagName"
                  :key="index1"
                  class="btn mr-2 mb-1 btn-outline-secondary"
                >
                  {{ items }}
                </b-badge>
              </div>
            </div>
            <div class="tag_btn" v-else >N/A</div>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5>Booking Credits</h5>
            <p class="booking_credits" v-if="item.bookingcredits>0">{{ item.bookingcredits }} Credits</p>
            <p class="booking_credits" v-else style="color:red;">Trial Booking</p>
          </b-col>
          <b-col md="6">
            <h5>Description</h5>
            <span v-html="limitTo(item.description,200)"></span>
                <a
                    class="description readmore cancelReadMore"
                    v-b-modal="'readmore' + item.bookingId"
                    v-show="item.description.length >= 200"
                    
                    >Read more</a
                >
            <UserModal title="Cancel Reason" :text="item.description" :id="String(item.bookingId)" />
          </b-col>
          <b-col md="6" class="mt-2">
            
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import * as getFirebase from "@/utils/FirebaseQueries/getQueries/getQueries";
import { dbCollections } from "@/utils/firebaseCollection.js";
import { mapGetters , mapActions } from "vuex";
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "@/views/SessionsManagement/CanceledSession(s)/cancelSessionDetail.vue"
import UserModal from "@/@core/components/modals/ReadMoreModal.vue";
import moment from "moment";
import router from '@/router';
export default {
  components: {
    BCard,
    BSpinner,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BButton,
    UserModal
  },
  data() {
    return {
      direction: false,
      isSpinner: false,
      isUpdating: false,
      currentitems: [],
      tutorType: [
        {
          1: "Valuable",
          2: "Elite",
          3: "Normal",
          4: "N/A",
        },
        {
          1: "light-primary",
          2: "light-danger",
          3: "light-success",
          4: "light-dark",
        },
      ],
      bookingIdForUpdate: "",
      rowsArrayDataForCancel: [],
      bookingTitle:"",
    };
  },
  created() {
    let self = this;
    this.isSpinner = true
    this.$root.$emit('startButtonDisabled')
    let cancelRecordFound = self.getCancelSessionList.length != 0;
    if(!cancelRecordFound){
      self.getCancelSessionDatas().then(res=>{
        this.cancelSessionDetailPageUp()
      }).catch(error=>{
        console.error("error",error)
      })
    }else{
      this.cancelSessionDetailPageUp()
      self.$root.$emit('startButtonDisabled')
    }
  },
  beforeRouteLeave(from,to,next){
   var self = this;
     var  name = "";
     var data = '';
     var datas = ''
     self.$root.$emit('cancelSessionDetailsForName', name,data,datas);
     next()
  },
  computed: {
    ...mapGetters({
      getCancelSessionList: "cancelSessionDetails/getCancelSession",
      getAllStatusList:'settings/getAllStatus',
      gettutorTypesList: "settings/getTutorTypes",
    }),
    
  },
  methods: {
    ...mapActions({
      getCancelSessionDatas: "cancelSessionDetails/getCancelSessionData"
    }),
    getTutorTypeStyle(value) {
        if(this.gettutorTypesList) {
            let index = this.gettutorTypesList.findIndex((val ) =>{
              return val.name == value
            });
            if(index > -1) {
                return {
                  'background-color' : this.gettutorTypesList[index].backgroundColor,
                  'color' : this.gettutorTypesList[index].textColor,
                  'border-color': this.gettutorTypesList[index].textColor,
                  'border-width': '1px',
                  'border-style': 'solid'
                }
            }
        } else {
            return ''
        }
    },
    cancelSessionDetailPageUp() {
      var self = this;
      try {
        self.$root.$emit('startButtonDisabled')
        this.rowsArrayDataForCancel = [];
        getFirebase.simpleGetDataFromSubCollectionWithDocIDV2(dbCollections.BOOKING,this.$route.params.id,(cancelDatas)=>{
        if(cancelDatas.status === false){
          self.$root.$emit('showToastMessage','Record not found.','danger');
          self.$router.push({ name: "canceled-session-mgmt" });
          self.isSpinner = false;
          return;
        }
        let cancelData = cancelDatas.data
          getFirebase.simpleGetDataFromSubCollectionWithDocIDV2(dbCollections.SESSIONS,cancelData.sessionDetails.id,(ress)=>{
            let res = ress.data
            self.$root.$emit('cancelSessionDetailsForName', cancelData.bookingId,cancelData.tutor,cancelData.id);
            this.bookingTitle = cancelData.bookingsTitle ? cancelData.bookingsTitle : "";
            var object = {
              bookingId: cancelData.bookingId,
              Id: cancelData.id,
              learnerChildName:
                (cancelData.learnerDetails
                  ? cancelData.learnerDetails
                  .firstName:'')+
                " " +
                (cancelData.learnerDetails
                  ?cancelData.learnerDetails
                  .lastName:''),
                tutorNameDetail:
                  (cancelData.tutorDetails
                    ?cancelData.tutorDetails
                    .firstName:'') +
                  " " +
                  (cancelData.tutorDetails
                    ?cancelData.tutorDetails
                    .lastName:''),
              sessionCansallationTime: cancelData.cancellationDate ? cancelData.cancellationDate :'',
              bookingcredits:
                cancelData.usedCredits ? cancelData.usedCredits : 0,
              cansalReason: cancelData.cancellationReason,
              sessionBooking:
                cancelData.startTime ? cancelData.startTime: '',
              sessionEnd: cancelData.endTime ? cancelData.endTime:'',
              status: cancelData.cancelReasonStatus,
              booked: cancelData.createdAt,
              tutorType:
                cancelData.tutorDetails
                  ? cancelData.tutorDetails
                  .tutorType: '',
              category:
                cancelData.categoryDetails
                  .categoryName,
              categoryId:
                cancelData.categoryDetails.id,
              sessionId:
                cancelData.sessionDetails.id,
              teachinglanguage: res.teachingLanguageDetails ? res.teachingLanguageDetails.title : '-',
              description: res.description ? res.description: '',
              tagName: res.tags,
              bookingTutor: cancelData.tutor,
              bookinglernerId:
                cancelData.learnerId,
              learnerAvtar: cancelData.learnerDetails.profileImage ? cancelData.learnerDetails.profileImage :"",
              tutorAvtar: cancelData.tutorDetails.profilePicture ? cancelData.tutorDetails.profilePicture :"",
            };
            this.rowsArrayDataForCancel.push(object);
            self.isSpinner = false;
            self.$root.$emit('stopButtonDisabled')
          }
        );
      })
      } catch (error) {
        console.error("error", error);
        self.isSpinner = false;
        errorEmail({
            projectName : process.env.VUE_APP_PROJECTNAME,
            environment : process.env.NODE_ENV,
            fileName : currentFileName,
            hookName : "cancelSessionDetailPageUp",
            errorMessage : error.message
          },(mailRes) => {
            if(mailRes.error){
              console.error(mailRes);
            }
          })
        setTimeout(() => {
          this.$root.$emit('stopButtonDisabled');
        }, 300);
      }
    },
    addZero(value) {
      if (value > 9) {
        return value;
      } else {
        return "0" + value;
      }
    },
    limitTo: function (value,lengthOfText) {
      if(value){
          if (value.length < lengthOfText){
              return value
          }else{

              return value.substring(0, lengthOfText) + "...";
          }
      }else{
          return "-";
      }
    },
    TemplateCreatedDate(TemplateCreatedDate) {
      var self = this;
      var cancelSessionPageDate = new Date(
        TemplateCreatedDate.seconds * 1000
      ).getDate();
      var cancelSessionPageMonth =
        new Date(TemplateCreatedDate.seconds * 1000).getMonth() + 1;
      var cancelSessionPageYear = new Date(
        TemplateCreatedDate.seconds * 1000
      ).getFullYear();
      var returncancelSessionPageDate =
        self.addZero(cancelSessionPageDate) +
        "-" +
        self.addZero(cancelSessionPageMonth) +
        "-" +
        cancelSessionPageYear +
        ", " +
        self.cancelCreateTime(
          new Date(TemplateCreatedDate.seconds * 1000)
        );

      return returncancelSessionPageDate;
    },
    TemplateEndDate(TemplateEndDate) {
      var self = this;
      var returnCancelDate = self.cancelCreateTime(
        new Date(TemplateEndDate.seconds * 1000)
      );
      return returnCancelDate;
    },
    sessionTimeCalculate(startTime , endTime) {
      if (moment(startTime.seconds*1000).format("DD-MM-YYYY") == moment(endTime.seconds*1000).format("DD-MM-YYYY")) {
        return `${moment(startTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")} to ${moment(endTime.seconds*1000).format("hh:mm A")}`
      } else {
        return `${moment(startTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")} to ${moment(endTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")}`
      }
    },
    cancelCreateTime(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      hours = hours<10 ? '0'+hours:hours
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
    getTutorTypeTextVariant(value){
      var variant = "light-";
      if(value == 1)
      {
        variant += 'success';
      }
      else if(value == 2)
      {
        variant += 'danger';
      }
      else if(value == 3)
      {
        variant += 'color';
      }
      else{
        variant ='badge text-capitalize badge-light-warning badge-pill'
      }
      return variant;
    },
    getTutorTypeBadgeVariant(value){
      var variant = "light-";
      if(value == "Normal")
      {
        variant += 'success';
      }
      else if(value == "Elite")
      {
        variant += 'danger';
      }
      else if(value == "Valuable")
      {
        variant += 'primary';
      }
      else{
        variant += 'warning'
      }
      return variant;
    },
  },
};
</script>
<style scope>
    .cancelReadMore{
      color: #0096DB !important;
      text-decoration: none !important;
    }
</style>
